import React from "react"
import { Container, Row, Col } from "react-bootstrap"

const HeaderPrivacyPolicy = () => {
  return (
    <section className="banner-style-one" id="privacy-policy">
      <span className="bubble-1"></span>
      <span className="bubble-2"></span>
      <span className="bubble-3"></span>
      <span className="bubble-4"></span>
      <span className="bubble-5"></span>
      <span className="bubble-6"></span>
      <Container>
        <Row>
          <Col xl={6} lg={8}>
            <div className="content-block">
              <h3>
              Kebijakan Privasi
              </h3>
            </div>
          </Col>
        </Row>
      </Container>
    </section>
  )
}

export default HeaderPrivacyPolicy
