import React, { useEffect, useState } from "react"
import { Container, Row } from "react-bootstrap"
import axios from "axios"
import { API } from "../../../config/api"

const DataPrivacyPolicy = () => {
  const [data, setData] = useState()
  const fetchData = async () => {
    const response = await axios.get(
      `${API.URL}/api/v1/securitys/privacy-policy`
    ).then(response => {
      setData(response?.data?.data)
    }).catch(err => {
      console.log(err)
    })
    return response
  }
  useEffect(() => {
    fetchData()
  }, [])
  return (
    <section className="blog-style-one blog-page" id="blog" style={{ marginRight: 15, marginLeft: 15 }}>
      <Container fluid>
        <Row>
          <div
            style={{ color: "black", width: '100%' }}
            dangerouslySetInnerHTML={{ __html: data?.content }}
          />
        </Row>
      </Container>
    </section>
  )
}

export default DataPrivacyPolicy
