import React from "react"
import Layout from "../components/layout"
import BlogPage from "../components/blog-page"
import Footer from "../components/footer"
import Seo from "../components/seo"
import HeaderPages from "../components/header-pages"
import HeaderPrivacyPolicy from "../components/privacy-policy"
import DataPrivacyPolicy from "../components/api/PrivacyPolicy/privacy-policy"

const PrivacyPolicy = () => {
  return (
    <Layout pageTitle="Home | Klabbelanja">
      <Seo title="Kebijakan Privasi | Klabbelanja" />
      <HeaderPages />
      {/* <HeaderPrivacyPolicy /> */}
      <DataPrivacyPolicy/>
      {/* <Footer /> */}
    </Layout>
  )
}

export default PrivacyPolicy
